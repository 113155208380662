<template>
  <base-wrapper :loading="loading">
    <datatable
      :columns="tableColumns"
      :total="stockBatches.meta.page.total"
      :perPage="stockBatches.meta.page.perPage"
      :currentPage="stockBatches.meta.page.currentPage"
      @pagechanged="onChangePage"
    >
      <template #tbody="{ classes }">
        <tr
          v-for="stockBatch in stockBatches.data"
          :key="stockBatch.id"
          :class="[classes.tr, 'bg-white cursor-pointer hover:bg-green-100']"
          @click="$router.push({ name: 'persiapan-stok.tambah', params: { id: stockBatch.id } })"
        >
          <td :class="classes.td">
            <p class="font-bold text-gray-900">
              {{ stockBatch.attributes.code }}

              <span v-if="stockBatch.attributes.product_type === 'free'"
                >(PROMO)</span
              >
            </p>
          </td>
          <td :class="classes.td">
            {{ stockBatch.attributes.product_code }}
          </td>
          <td :class="classes.td">
            {{ stockBatch.attributes.product_name }}
          </td>
          <td :class="classes.td">
            {{ stockBatch.attributes.stock_qty | toCurrency }}
          </td>
          <td :class="classes.td">
            {{ stockBatch.attributes.expire_at | formatDate }}
          </td>
        </tr>
      </template>
    </datatable>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      loading: false,
      stockBatches: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    tableColumns() {
      return [
        { id: 'code', name: 'No. Batch' },
        { id: 'product_code', name: 'Kode Barang' },
        { id: 'product_name', name: 'Nama Barang' },
        { id: 'product_qty', name: 'Jumlah' },
        { id: 'expire_at', name: 'Kadaluarsa' }
      ];
    },
  },
  methods: {
    async loadStockBatches(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/warehouses/${this.me.current_warehouse}/stock-batches`,
        {
          params: {
            'fields[stock-batches]':
              'code,product_type,product_code,product_name,stock_qty,expire_at',
            'page[size]': 5,
            'filter[is_packed]': false,
            sort: '-id',
            ...params,
          },
        }
      );

      if (!err) {
        this.stockBatches = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadStockBatches({
        'page[number]': page,
      });
    }
  },
  created() {
    this.loadStockBatches();
  },
};
</script>
