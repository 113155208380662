<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input
          type="search"
          placeholder="Cari Kode"
          :shadow="false"
          debounce
          v-model="filter.qrcode"
          @native-input="loadProductQrCodes"
        />
        <base-input
          type="search"
          placeholder="Cari Kode Produk"
          :shadow="false"
          debounce
          v-model="filter.product_code"
          @native-input="loadProductQrCodes"
        />
        <product-qr-code-status-select
          jsonapi
          v-model="filter.status"
          @change="loadProductQrCodes"
        />
      </div>
      <datatable
        :columns="tableColumns"
        :total="productQrCodes.meta.page.total"
        :perPage="productQrCodes.meta.page.perPage"
        :currentPage="productQrCodes.meta.page.currentPage"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="item in productQrCodes.data"
            :key="item.id"
            :class="[classes.tr, 'bg-white']"
          >
            <td :class="classes.td">
              <qr-code :text="item.attributes.qrcode" :size="100" />
            </td>
            <td :class="classes.td">
              {{ item.attributes.product_code ?? '-' }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <product-qr-code-badge :product-qr-code="item" jsonapi />
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import QrCode from 'vue-qrcode-component';
import { mapGetters, mapActions } from 'vuex';
import ProductQrCodeBadge from '@/components/product-qr-code/product-qr-code-badge.vue';
import ProductQrCodeStatusSelect from '@/components/product-qr-code/product-qr-code-select.vue';

export default {
  mixins: [requestMixin],
  components: {
    QrCode,
    ProductQrCodeBadge,
    ProductQrCodeStatusSelect,
  },
  data() {
    return {
      filter: {
        qrcode: null,
        product_code: null,
        status: null,
      },
      loading: false,
      productQrCodes: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    tableColumns() {
      return [
        { id: 'qrcode', name: 'QR Code' },
        { id: 'product', name: 'Kode Barang' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadProductQrCodes(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}/product-qr-codes`,
        {
          params: {
            'fields[product-qr-codes]':
              'qrcode,is_prepared,is_valid,is_packed,is_ordered,is_scanned,is_active,is_used,product_code,status,logs',
            'filter[qrcode]': this.filter.qrcode || null,
            'filter[product_code]': this.filter.product_code || null,
            ...this.filter.status,
            'page[size]': 5,
            ...params,
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
          timeout: 1000,
        });

        this.productQrCodes = {
          data: [],
          meta: {
            page: {},
          },
        };
      } else {
        this.productQrCodes = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadProductQrCodes({
        'page[number]': page,
      });
    },
  },
  created() {
    this.loadProductQrCodes();
  },
};
</script>
