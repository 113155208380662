<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-6xl"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card
        :with-style="false"
        title="Detail Stock"
        description="Berdasarkan data stock yang telah dibuat"
      >
        <div class="border-t pt-6">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <div v-if="columns.includes('product_code')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.product_code }}
              </dd>
            </div>
            <div v-if="columns.includes('product_name')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.product_name }}
              </dd>
            </div>
            <div v-if="columns.includes('stock_qty')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.stock_qty }}
              </dd>
            </div>
            <div v-if="columns.includes('expired_qty')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock Kadaluarsa</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.expired_count }}
              </dd>
            </div>
            <div v-if="columns.includes('damaged_qty')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock Rusak</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.damaged_count }}
              </dd>
            </div>
            <div
              v-if="columns.includes('suitable_for_sale_qty')"
              class="sm:col-span-2"
            >
              <dt class="text-sm font-bold text-gray-700">Stock Layak Jual</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.suitable_for_sale_count }}
              </dd>
            </div>
            <div
              v-if="columns.includes('unsuitable_for_sale_qty')"
              class="sm:col-span-2"
            >
              <dt class="text-sm font-bold text-gray-700">
                Stock Tidak Layak Jual
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.unsuitable_for_sale_count }}
              </dd>
            </div>
            <div v-if="columns.includes('free_qty')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock Free</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.free_qty }}
              </dd>
            </div>
            <div v-if="columns.includes('unit')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Satuan</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product ? product.attributes.unit_name : '-' }}
              </dd>
            </div>
            <div v-if="columns.includes('volume')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Isi</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product ? product.attributes.volume : '-' }}
              </dd>
            </div>
            <div v-if="columns.includes('pv')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Point Value (PV)</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ (product ? product.attributes.point : 0) | toCurrency }}
              </dd>
            </div>
            <div v-if="columns.includes('bv')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Bonus Value (PV)</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ (product ? product.attributes.bonus : 0) | toCurrency }}
              </dd>
            </div>
            <div v-if="columns.includes('weight')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Total Berat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ (product ? product.attributes.weight : 0) | toCurrency }}
              </dd>
            </div>
            <div v-if="columns.includes('stock')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stok</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.stock_real | toCurrency }}
              </dd>
            </div>
            <div v-if="columns.includes('stock_restock')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stok Restock</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.stock_loan_qty | toCurrency }}
              </dd>
            </div>
            <div v-if="columns.includes('order_status')" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status Order</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    stock.data.attributes.is_orderable ? 'blue' : 'yellow'
                  "
                >
                  {{
                    stock.data.attributes.is_orderable ? 'Open' : 'Close'
                  }}</base-badge
                >
              </dd>
            </div>
            <div
              v-if="columns.includes('pre_order_status')"
              class="sm:col-span-2"
            >
              <dt class="text-sm font-bold text-gray-700">Status Pre Order</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    stock.data.attributes.is_pre_orderable ? 'blue' : 'yellow'
                  "
                >
                  {{
                    stock.data.attributes.is_pre_orderable ? 'Open' : 'Close'
                  }}</base-badge
                >
              </dd>
            </div>
            <div
              v-if="columns.includes('license_status')"
              class="sm:col-span-2"
            >
              <dt class="text-sm font-bold text-gray-700">Status Izin</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    stock.data.attributes.license_status ? 'blue' : 'yellow'
                  "
                >
                  {{
                    stock.data.attributes.license_status ? 'Open' : 'Close'
                  }}</base-badge
                >
              </dd>
            </div>
            <div
              v-if="columns.includes('distributor_price')"
              class="sm:col-span-2"
            >
              <dt class="text-sm font-bold text-gray-700">Harga Distributor</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  (product ? product.attributes.supplier_price : 0) | toCurrency
                }}
              </dd>
            </div>
            <div
              v-if="columns.includes('stockist_price')"
              class="sm:col-span-2"
            >
              <dt class="text-sm font-bold text-gray-700">Harga Stockist</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  getProductPriceByBuyerType('Stockist')?.attributes?.price
                    | toCurrency
                }}
              </dd>
            </div>
            <div
              v-if="columns.includes('consumen_price')"
              class="sm:col-span-2"
            >
              <dt class="text-sm font-bold text-gray-700">Harga Konsumen</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  getProductPriceByBuyerType('Konsumen')?.attributes?.price
                    | toCurrency
                }}
              </dd>
            </div>
            <div class="sm:col-span-6">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <dt class="text-sm font-bold text-gray-700">Riwayat Stock</dt>
                  <period-select
                    dropdown
                    v-model="filterHistories.period_uuid"
                    @change="loadStockHistories"
                  />
                </div>
                <div class="flex gap-8">
                  <p class="text-sm text-gray-700">Stock Awal</p>
                  <p class="text-sm font-bold text-gray-900">
                    {{ stockHistories.meta.last_period_stock | toCurrency }}
                  </p>
                </div>
              </div>
              <dd class="mt-3">
                <stock-history-table
                  :data="stockHistories"
                  @change-page="onChangePageHistory"
                />
              </dd>
            </div>
          </dl>
        </div>
      </base-card>
    </base-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import StockHistoryTable from './stock-history-table.vue';
import PeriodSelect from '@/components/period/period-select.vue';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    StockHistoryTable,
    PeriodSelect,
  },
  props: {
    visible: Boolean,
    stockId: String,
    columns: {
      type: Array,
      default: () => [
        'product_code',
        'product_name',
        'unit',
        'volume',
        'pv',
        'bv',
        'weight',
        'stock',
        'stock_restock',
        'order_status',
        'pre_order_status',
        'distributor_price',
        'stockist_price',
        'consumen_price',
      ],
    },
  },
  emits: ['close'],
  data() {
    return {
      filterHistories: {
        period_uuid: null,
      },
      loadingStock: false,
      loadingStockHistory: false,
      loadingProductPrice: false,
      stock: {
        data: {
          attributes: {},
          relationships: {
            product: {
              data: {},
            },
          },
        },
        included: [],
      },
      productPrices: {
        data: [],
      },
      stockHistories: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    loading() {
      return (
        this.loadingStock ||
        this.loadingStockHistory ||
        this.loadingProductPrice
      );
    },
    product() {
      return this.getSingleIncluded(
        this.stock,
        this.stock.data.relationships.product.data.id
      );
    },
  },
  methods: {
    getProductPriceByBuyerType(buyerType) {
      return this.productPrices.data.find(
        (productPrice) => productPrice.attributes['buyer-type'] === buyerType
      );
    },
    async loadCurrentPeriod() {
      const [res, err] = await this.request(
        '/api/v1/periods/-actions/get-current-period'
      );

      if (!err) {
        this.filterHistories.period_uuid = res.data.id;
      }
    },
    async loadProductPrices() {
      this.loadingProductPrice = true;

      const [res, err] = await this.request(
        `/api/v1/products/${this.product.id}/product-prices`,
        {
          params: {
            'fields[product-prices]': 'buyer-type,price',
            'filter[buyer-type-in]': 'Stockist,Konsumen',
          },
        }
      );

      if (!err) {
        this.productPrices = res;
      }

      this.loadingProductPrice = false;
    },
    async loadStockHistories(params) {
      this.loadingStockHistory = true;

      const [res, err] = await this.request(
        `/api/v1/stocks/${this.stock.data.id}/stock-trxes`,
        {
          params: {
            'page[size]': 5,
            sort: 'latest',
            'fields[stock-trxes]':
              'createdAt,amount,type,current_stock,description',
            'filter[period_uuid]': this.filterHistories.period_uuid,
            ...params,
          },
        }
      );

      if (!err) {
        this.stockHistories = res;
      }

      this.loadingStockHistory = false;
    },
    onChangePageHistory(page) {
      this.loadStockHistories({
        'page[number]': page,
      });
    },
    async onOpened() {
      this.loadingStock = true;

      const [res, err] = await this.request(`/api/v1/stocks/${this.stockId}`, {
        params: {
          include: 'product.product-prices',
          'fields[stocks]':
            'product_code,product_name,stock_real,stock_loan_qty,product,is_orderable,is_pre_orderable,stock_qty,expired_count,damaged_count,suitable_for_sale_count,unsuitable_for_sale_count',
          'fields[products]':
            'unit_name,volume,point,bonus,weight,supplier_price',
        },
      });

      if (!err) {
        this.stock = res;

        this.loadProductPrices();
        this.loadStockHistories();
      }

      this.loadingStock = false;
    },
  },
  created() {
    this.loadCurrentPeriod();
  },
};
</script>
