var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-6"},[_c('div',{staticClass:"flex justify-end gap-x-2"},[_c('base-input',{attrs:{"type":"search","shadow":false,"placeholder":"Cari Kode","debounce":""},on:{"native-input":_vm.loadStocks},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('export-stock-dropdown')],1),_c('datatable',{attrs:{"id":"daftar_stok","columns":[
        { id: 'code', name: 'Kode Barang' },
        { id: 'name', name: 'Nama Barang' },
        { id: 'point', name: 'P/N' },
        {
          id: 'stock_qty',
          name: 'Stock Di Gudang',
          theadClass: 'text-right',
        },
        {
          id: 'stock_ordered',
          name: 'Jumlah Diorder',
          theadClass: 'text-right',
        },
        {
          id: 'stock_real',
          name: 'Stock Tersedia',
          theadClass: 'text-right',
        },
        {
          id: 'stock_restock',
          name: 'Stock Restock',
          theadClass: 'text-right',
        },
      ],"total":_vm.stocks.meta.page.total,"perPage":_vm.stocks.meta.page.perPage,"currentPage":_vm.stocks.meta.page.currentPage,"is-empty":!_vm.stocks.data.length},on:{"pagechanged":_vm.onPageChange},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return [_c('tbody',{class:classes.tbody},_vm._l((_vm.stocks.data),function(stock){return _c('tr',{key:stock.id,class:[classes.tr, 'cursor-pointer bg-white hover:bg-gray-100'],on:{"click":function($event){return _vm.onView(stock)}}},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(stock.attributes.product_code)+" ")])]),_c('td',{class:classes.td},[_vm._v(_vm._s(stock.attributes.product_name))]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded(_vm.stocks, stock.relationships.product.data.id) .attributes.is_point ? 'P' : 'N')+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(stock.attributes.stock_qty))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(stock.attributes.stock_ordered))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(stock.attributes.stock_real))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(stock.attributes.stock_loan_qty))+" ")])])}),0)]}}])})],1),_c('view-stock-modal',{attrs:{"visible":_vm.viewModal.visible,"stock-id":_vm.viewModal.id},on:{"close":function($event){_vm.viewModal.visible = false}}}),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }