<template>
  <base-wrapper>
    <div class="space-y-6">
      <div class="flex justify-end gap-x-2">
        <base-input
          type="search"
          :shadow="false"
          placeholder="Cari Kode"
          debounce
          v-model="filter.search"
          @native-input="loadStocks"
        />
        <export-stock-dropdown />
      </div>
      <datatable
        id="daftar_stok"
        :columns="[
          { id: 'code', name: 'Kode Barang' },
          { id: 'name', name: 'Nama Barang' },
          { id: 'point', name: 'P/N' },
          {
            id: 'stock_qty',
            name: 'Stock Di Gudang',
            theadClass: 'text-right',
          },
          {
            id: 'stock_ordered',
            name: 'Jumlah Diorder',
            theadClass: 'text-right',
          },
          {
            id: 'stock_real',
            name: 'Stock Tersedia',
            theadClass: 'text-right',
          },
          {
            id: 'stock_restock',
            name: 'Stock Restock',
            theadClass: 'text-right',
          },
        ]"
        :total="stocks.meta.page.total"
        :perPage="stocks.meta.page.perPage"
        :currentPage="stocks.meta.page.currentPage"
        :is-empty="!stocks.data.length"
        @pagechanged="onPageChange"
      >
        <template #tbody="{ classes }">
          <tbody :class="classes.tbody">
            <tr
              v-for="stock in stocks.data"
              :key="stock.id"
              :class="[classes.tr, 'cursor-pointer bg-white hover:bg-gray-100']"
              @click="onView(stock)"
            >
              <td :class="classes.td">
                <p class="font-bold text-gray-900">
                  {{ stock.attributes.product_code }}
                </p>
              </td>
              <td :class="classes.td">{{ stock.attributes.product_name }}</td>
              <td :class="classes.td">
                {{
                  getSingleIncluded(stocks, stock.relationships.product.data.id)
                    .attributes.is_point
                    ? 'P'
                    : 'N'
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ stock.attributes.stock_qty | toCurrency }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ stock.attributes.stock_ordered | toCurrency }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ stock.attributes.stock_real | toCurrency }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ stock.attributes.stock_loan_qty | toCurrency }}
              </td>
            </tr>
          </tbody>
        </template>
      </datatable>
    </div>

    <view-stock-modal
      :visible="viewModal.visible"
      :stock-id="viewModal.id"
      @close="viewModal.visible = false"
    />

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import ExportStockDropdown from '@/components/stock/export-stock-dropdown.vue';
import ViewStockModal from '@/components/stock/view-stock-modal.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    ExportStockDropdown,
    ViewStockModal,
  },
  data() {
    return {
      filter: {
        search: null,
      },
      loading: false,
      stocks: {
        data: [],
        meta: {
          page: {},
        },
      },
      viewModal: {
        id: null,
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
  },
  methods: {
    async loadStocks(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/warehouses/${this.me.current_warehouse}/stocks`,
        {
          params: {
            'page[size]': 5,
            'filter[search]': this.filter.search,
            include: 'product',
            'fields[stocks]':
              'product_code,product_name,product,stock_qty,stock_ordered,stock_real,stock_loan_qty',
            'fields[products]': 'is_point',
            ...params,
          },
        }
      );

      if (!err) {
        this.stocks = res;
      }

      this.loading = false;
    },
    onPageChange(page) {
      this.loadStocks({
        'page[number]': page,
      });
    },
    onView(stock) {
      this.viewModal.id = stock.id;
      this.viewModal.visible = true;
    },
  },
  created() {
    this.loadStocks();
  },
};
</script>
