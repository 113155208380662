<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end">
        <base-button :to="{ name: 'manajemen-batch-stok.tambah' }">
          <Icon icon="heroicons:plus" class="h-4 w-4" />
          Tambah Batch
        </base-button>
      </div>
      <datatable
        :columns="tableColumns"
        :total="stockBatches.meta.page.total"
        :perPage="stockBatches.meta.page.perPage"
        :currentPage="stockBatches.meta.page.currentPage"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="stockBatch in stockBatches.data"
            :key="stockBatch.id"
            :class="[classes.tr, 'bg-white']"
          >
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{ stockBatch.attributes.code }}

                <span v-if="stockBatch.attributes.product_type === 'free'"
                  >(PROMO)</span
                >
              </p>
            </td>
            <td :class="classes.td">
              {{ stockBatch.attributes.pickup_method_label }}
            </td>
            <td :class="classes.td">
              {{ stockBatch.attributes.product_code }}
            </td>
            <td :class="classes.td">
              {{ stockBatch.attributes.product_name }}
            </td>
            <td :class="classes.td">
              {{ stockBatch.attributes.stock_qty | toCurrency }}
            </td>
            <td :class="classes.td">
              {{ stockBatch.attributes.expire_at | formatDate }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge :color="getBadgeColor(stockBatch)">{{
                getBadgeText(stockBatch)
              }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      loading: false,
      stockBatches: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    tableColumns() {
      return [
        { id: 'code', name: 'No. Batch' },
        { id: 'type', name: 'Tipe' },
        { id: 'product_code', name: 'Kode Barang' },
        { id: 'product_name', name: 'Nama Barang' },
        { id: 'product_qty', name: 'Jumlah' },
        { id: 'expire_at', name: 'Kadaluarsa' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    getBadgeColor(stockBatch) {
      if (!stockBatch.attributes.is_packed) {
        return 'yellow';
      }

      return stockBatch.attributes.is_active ? 'green' : 'red';
    },
    getBadgeText(stockBatch) {
      if (!stockBatch.attributes.is_packed) {
        return 'Persiapan Stok';
      }

      return stockBatch.attributes.is_active ? 'Aktif' : 'Tidak Aktif';
    },
    async loadStockBatches(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/warehouses/${this.me.current_warehouse}/stock-batches`,
        {
          params: {
            'fields[stock-batches]':
              'code,product_type,product_code,product_name,stock_qty,is_active,is_packed,expire_at,pickup_method_label',
            'page[size]': 5,
            sort: '-id',
            ...params,
          },
        }
      );

      if (!err) {
        this.stockBatches = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadStockBatches({
        'page[number]': page,
      });
    }
  },
  created() {
    this.loadStockBatches();
  },
};
</script>
