<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <base-card title="Tambah Batch Stok Barang">
        <div class="grid grid-cols-6 gap-4">
          <search-product-input
            class="col-span-3"
            :params="{ 'filter[supplier_id]': me.office_id }"
            v-model="form.product"
          />
          <base-input
            class="col-span-3"
            inset
            disabled
            label="Nama Barang"
            type="text"
            placeholder="Masukkan nama barang"
            :value="form.product.name"
          />
          <base-input
            class="col-span-2"
            inset
            label="Nama Batch"
            type="text"
            placeholder="Masukkan nama batch"
            v-model="form.batch.name"
          />
          <base-input
            class="col-span-2"
            inset
            currency
            label="Jumlah Barang"
            type="text"
            placeholder="Masukkan jumlah barang"
            v-model="form.batch.qty"
          />
          <base-input
            class="col-span-2"
            inset
            label="Tanggal Kadaluarsa"
            type="date"
            v-model="form.batch.expire_at"
          />
        </div>
      </base-card>

      <div class="flex justify-end gap-2">
        <base-button :disabled="!valid" @click="confirmationVisible = true">
          <Icon icon="heroicons:document-check-solid" class="h-4 w-4" />
          Simpan
        </base-button>
        <base-button color="white" :to="backRoute">
          <Icon icon="heroicons:arrow-uturn-left" class="h-4 w-4" />
          Kembali
        </base-button>
      </div>
    </div>

    <base-confirm
      icon-type="badge"
      type="success"
      title="Konfirmasi Tambah Batch Stok"
      message="Apakah anda yakin ingin menyimpan batch stok barang ini?"
      :visible="confirmationVisible"
      @close="confirmationVisible = false"
      @confirmed="onConfirmed"
    />
  </base-wrapper>
</template>

<script>
import SearchProductInput from '@/components/product/search-product-input.vue';
import { requestMixin } from '@/mixins/request/request';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  mixins: [requestMixin],
  components: {
    BaseConfirm,
    SearchProductInput,
  },
  data() {
    return {
      confirmationVisible: false,
      form: {
        batch: {
          expire_at: null,
          name: null,
          qty: null,
        },
        product: {
          id: null,
          code: null,
          originalCode: null,
          searchCode: null,
          name: null,
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    backRoute() {
      const pengeluaranId = this.$route.query.pengeluaran_id;

      if (!pengeluaranId) {
        return { name: 'manajemen-batch-stok' };
      }

      return {
        name: 'gudang.pengeluaran.dari-supplier.tambah',
        params: { id: pengeluaranId },
      };
    },
    valid() {
      if (!this.form.batch.qty) {
        return false;
      }

      return (
        this.form.batch.name &&
        Number(this.form.batch.qty.replace(/\D/gi, '')) > 0 &&
        this.form.batch.expire_at &&
        this.form.product.id
      );
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onConfirmed() {
      this.loading = true;

      const [, err] = await this.request('/api/v1/stock-batches', {
        method: 'post',
        data: {
          data: {
            type: 'stock-batches',
            attributes: {
              code: this.form.batch.name,
              stock_qty: Number(this.form.batch.qty.replace(/\D/gi, '')),
              expire_at: dayjs(this.form.batch.expire_at).toISOString(),
            },
            relationships: {
              warehouse: {
                data: {
                  type: 'warehouses',
                  id: this.me.current_warehouse,
                },
              },
              product: {
                data: {
                  type: 'products',
                  id: this.form.product.id,
                },
              },
            },
          },
        },
      });

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.$router.push(this.backRoute);
      }

      this.loading = false;
    },
    async loadOrderDetail(orderDetailId) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/order-details/${orderDetailId}`,
        {
          params: {
            'fields[order-details]':
              'product_id,product_code,product_name,product_qty',
          },
        }
      );

      if (!err) {
        this.form.product.id = res.data.attributes.product_id;
        this.form.product.code = res.data.attributes.product_code;
        this.form.product.originalCode = res.data.attributes.product_code;
        this.form.product.searchCode = res.data.attributes.product_code;
        this.form.product.name = res.data.attributes.product_name;
        this.form.batch.qty = `${res.data.attributes.product_qty}`;
      }

      this.loading = false;
    },
  },
  created() {
    const orderDetailId = this.$route.query.order_detail_id;

    if (orderDetailId) {
      this.loadOrderDetail(orderDetailId);
    }
  },
};
</script>
