<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <base-card title="Detail Batch Stok">
        <div class="grid grid-cols-6 gap-4">
          <base-input
            inset
            class="col-span-2"
            label="No. Batch"
            placeholder="-"
            disabled
            :value="stockBatch.data.attributes.code"
          />
          <base-input
            inset
            class="col-span-2"
            label="Kode Barang"
            placeholder="-"
            disabled
            :value="stockBatch.data.attributes.product_code"
          />
          <base-input
            inset
            class="col-span-2"
            label="Nama Barang"
            placeholder="-"
            disabled
            :value="stockBatch.data.attributes.product_name"
          />
          <base-input
            inset
            class="col-span-3"
            label="Jumlah"
            placeholder="-"
            disabled
            :value="stockBatch.data.attributes.stock_qty | toCurrency"
          />
          <base-input
            inset
            class="col-span-3"
            label="Kadaluarsa"
            placeholder="-"
            disabled
            :value="stockBatch.data.attributes.expire_at | formatDate"
          />
        </div>
      </base-card>
      <base-card title="Scan QR">
        <div class="space-y-4">
          <div class="flex gap-4">
            <base-radio
              val="product"
              label="Scan Barang"
              v-model="form.scanMode"
            />
            <base-radio val="box" label="Scan Box" v-model="form.scanMode" />
          </div>

          <datatable :columns="qrDataColumns">
            <template #tbody="{ classes }">
              <tr :class="classes.tr">
                <td :class="classes.td">Barang</td>
                <td :class="classes.td"></td>
                <td :class="classes.td">{{ form.scanned.product_qty }}</td>
                <td :class="classes.td"></td>
                <td :class="classes.td"></td>
              </tr>
              <tr
                v-for="(box, index) in form.scanned.boxes"
                :key="index"
                :class="[classes.tr]"
              >
                <td :class="classes.td">
                  <div class="flex items-center gap-4">
                    <span>Box</span>
                    <base-button
                      size="sm"
                      @click="onScanBox(index)"
                      :color="
                        form.scanned.currentScanBoxIndex === index
                          ? 'warning'
                          : 'white'
                      "
                      >{{
                        form.scanned.currentScanBoxIndex === index
                          ? 'Stop Scan Barang'
                          : 'Scan Barang'
                      }}</base-button
                    >
                  </div>
                </td>
                <td :class="classes.td">
                  <base-select
                    size="sm"
                    :shadow="false"
                    :options="productBoxesOptions"
                    v-model="form.scanned.boxes[index].productBoxUuid"
                    @change="onChangeBox(index)"
                  />
                </td>
                <td :class="classes.td">{{ box.qty }}</td>
                <td :class="classes.td">
                  {{ form.scanned.boxes[index].volume }}
                </td>
                <td :class="[classes.td, 'text-center']">
                  <span
                    v-if="box.volume < 1"
                    class="text-sm font-bold text-red-600"
                    >Isi Kosong</span
                  >
                  <span
                    v-else-if="box.qty < box.volume"
                    class="text-sm font-bold text-yellow-600"
                    >Belum Penuh</span
                  >
                  <span v-else class="text-sm font-bold text-green-600"
                    >Penuh</span
                  >
                </td>
              </tr>
            </template>
          </datatable>

          <p v-if="!valid" class="text-sm italic text-red-600">
            {{ disabledMessage }}
          </p>
        </div>
      </base-card>
      <div class="flex justify-end gap-2">
        <base-button v-if="false" @click="onRandomScan">
          <Icon icon="heroicons:printer-solid" class="h-4 w-4" />
          Scan
        </base-button>
        <base-button :disabled="!valid" @click="confirmationVisible = true">
          <Icon icon="heroicons:document-check-solid" class="h-4 w-4" />
          Simpan
        </base-button>
        <base-button color="white" :to="{ name: 'persiapan-stok' }">
          <Icon icon="heroicons:arrow-uturn-left" class="h-4 w-4" />
          Kembali
        </base-button>
      </div>
    </div>

    <base-confirm
      icon-type="badge"
      type="success"
      title="Konfirmasi Persiapan Stok"
      message="Apakah anda yakin ingin menyiapkan stok barang ini?"
      :visible="confirmationVisible"
      @close="confirmationVisible = false"
      @confirmed="onSubmit"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions, mapGetters } from 'vuex';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import BaseRadio from '@/components/base/BaseRadio.vue';
import { randomInt } from '@/services/utils.service';

export default {
  mixins: [requestMixin],
  components: {
    BaseConfirm,
    BaseRadio,
  },
  data() {
    return {
      form: {
        scanMode: 'product',
        scanned: {
          qrcodes: [],
          currentScanBoxIndex: null,
          product_qty: 0,
          boxes: [],
        },
      },
      confirmationVisible: false,
      loadingStockBatch: false,
      loadingSubmit: false,
      loadingSubmitScan: false,
      loadingProductBoxes: false,
      stockBatch: {
        data: {
          attributes: {},
        },
      },
      productBoxes: {
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    loading() {
      return (
        this.loadingStockBatch ||
        this.loadingSubmit ||
        this.loadingSubmitScan ||
        this.loadingProductBoxes
      );
    },
    disabledMessage() {
      if (this.remainderQty) {
        return `Kurang ${this.remainderQty} qr code`;
      }

      if (!this.validBoxQty) {
        return `Isi dan qty box tidak boleh kosong`;
      }

      if (!this.validBoxProductId) {
        return `Nama box harus diisi`;
      }

      return null;
    },
    productBoxesOptions() {
      return [
        {
          key: 'null',
          label: 'Pilih Box',
          value: null,
        },
        ...this.productBoxes.data.map((box) => ({
          key: box.id,
          label: box.attributes.name,
          value: box.id,
        })),
      ];
    },
    qrDataColumns() {
      return [
        { id: 'type', name: 'Tipe' },
        { id: 'name', name: 'Nama Box' },
        { id: 'qty', name: 'Jumlah' },
        { id: 'volume', name: 'Isi' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
      ];
    },
    remainderQty() {
      return this.stockBatch.data.attributes.stock_qty - this.totalQty;
    },
    totalQty() {
      return (
        this.form.scanned.product_qty +
        this.form.scanned.boxes.reduce((total, curr) => total + curr.qty, 0)
      );
    },
    validBoxProductId() {
      return this.form.scanned.boxes.every(
        (box) => box.productBoxUuid !== null
      );
    },
    validBoxQty() {
      return this.form.scanned.boxes.every(
        (box) => box.volume > 0 && box.qty >= box.volume
      );
    },
    valid() {
      if (this.remainderQty !== 0) {
        return false;
      }

      if (!this.validBoxQty) {
        return false;
      }

      return this.validBoxProductId;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadProductBoxes() {
      this.loadingProductBoxes = true;

      const [res, err] = await this.request(
        `/api/v1/products/${this.stockBatch.data.relationships.product.data.id}/product-boxes`
      );

      if (!err) {
        this.productBoxes = res;
      }

      this.loadingProductBoxes = false;
    },
    async loadStockBatch() {
      this.loadingStockBatch = true;

      const [res, err] = await this.request(
        `/api/v1/stock-batches/${this.$route.params.id}`,
        {
          params: {
            include: 'boxes,product',
            'fields[stock-batches]':
              'code,stock_qty,stock_packed_qty,product_code,product_name,expire_at,qrcodes,boxes,product',
            'fields[stock-batch-boxes]': 'name,volume,qty,product_box_uuid',
            'fields[products]': 'code',
          },
        }
      );

      if (!err) {
        this.stockBatch = res;

        this.loadProductBoxes();

        const { qrcodes } = res.data.attributes;

        this.form.scanned.product_qty = res.data.attributes.stock_packed_qty;

        if (Array.isArray(qrcodes)) {
          this.form.scanned.qrcodes = res.data.attributes.qrcodes;
        }

        if (
          res.data.relationships.boxes.data &&
          res.data.relationships.boxes.data.length
        ) {
          this.form.scanned.boxes = this.getIncludedByType(
            res,
            'stock-batch-boxes'
          ).map((box) => ({
            id: box.id,
            name: box.attributes.name,
            qty: box.attributes.qty,
            volume: box.attributes.volume,
            productBoxUuid: box.attributes.product_box_uuid,
            originalProductBoxUuid: box.attributes.product_box_uuid,
          }));
        }
      }

      this.loadingStockBatch = false;
    },
    async onChangeBox(index) {
      const box = this.form.scanned.boxes[index];

      this.loadingSubmitScan = true;

      const [, err] = await this.request(
        `/api/v1/stock-batch-boxes/${box.id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'stock-batch-boxes',
              id: box.id,
              relationships: {
                'product-box': {
                  data: {
                    type: 'products',
                    id: box.productBoxUuid,
                  },
                },
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
          timeout: 1000,
        });

        this.form.scanned.boxes[index].productBoxUuid =
          box.originalProductBoxUuid;
      } else {
        const productBox = this.productBoxes.data.find(
          (productBox) => productBox.id === box.productBoxUuid
        );

        this.form.scanned.boxes[index].originalProductBoxUuid =
          box.productBoxUuid;
        this.form.scanned.boxes[index].volume =
          productBox.attributes.product_count;
      }

      this.loadingSubmitScan = false;
    },
    onQrScanned(e) {
      const { text: qrcode } = e.detail;

      if (this.remainderQty === 0) {
        this.createAlert({
          status: 'warning',
          data: 'Total QR code tidak boleh melebihi qty batch',
          timeout: 1000,
        });
      } else {
        if (this.form.scanned.qrcodes.includes(qrcode)) {
          this.createAlert({
            status: 'warning',
            data: 'QR Code sudah digunakan',
            timeout: 1000,
          });
        } else {
          if (this.form.scanned.currentScanBoxIndex !== null) {
            const box =
              this.form.scanned.boxes[this.form.scanned.currentScanBoxIndex];

            if (box.qty < box.volume) {
              this.scanProductBox(qrcode);
            } else {
              this.createAlert({
                status: 'warning',
                data: 'Qty barang pada box melebihi volume box',
                timeout: 1000,
              });
            }
          } else {
            if (this.form.scanMode === 'product') {
              this.scanProduct(qrcode);
            } else {
              this.scanBox(qrcode);
            }
          }
        }
      }
    },
    onRandomScan() {
      const qrcodes = [
        '1UzM8qC3koTyReqD',
        '25kmlPNzzwLbx68Z',
        '5SXsvdMkS1tJ2kNN',
        '7azCnw4fhiSIhlGI',
        '84pSQj1gICUV0ooQ',
        '86ibXtH8hunaloNn',
        '8fGiEkuao22X1yAb',
        '8G87uhWKXBVWyzVq',
        '8mPy8rSsI3VPpBLn',
        '9FMo15hpSRt0QDk1',
        'AJpxHuyCmUAGhkdo',
        'aN7Wee88R7MJnWQq',
        'aRqHDsFJSVfTgWZs',
        'BvU0Uwex6H1mRerZ',
        'ejUX6tc4FnIOgJDQ',
        'FaAphbcpnu9c5OBY',
        'G9JT8Q9GTJJ8wbkY',
        'gWT5gg0cKEoiolw8',
        'IbpsR4qOtCBIJ8Ap',
        'iL1hvWstMwFPKAMx',
        'jHggRlTJoJD2KoPR',
        'kGOpoLH8IAgoSGHo',
        'McXLtrICcveExA7i',
        'mmDnXXuXtFU9asuy',
        'mo64V92lMJDjTAf9',
        'oOGc3NtzqhUDqDQM',
        'OS2kkMPnmUuhYeTA',
        'pTP0qqEP4DXuqskh',
        'PxdlkFidxRpof422',
        'qfsAQYNKQYGH9gvJ',
        'Rdg6NLVNERQrv2B9',
        'rhYNuH4ULmYACw6k',
        'SWjNNsrkAuIQV9iK',
        'vafOQdk2lXyKmLLA',
        'vBHdTpkZvS4QWZ14',
        'ViUBGcDnVAKKimic',
        'vMILtQjRLXUODRHK',
        'VtdYZ62EkFUsqiDy',
        'X2xSdxaVLqiD66Lm',
        'x7vdXOsKEoFKPjnI',
      ];
      const text = qrcodes[randomInt(qrcodes.length)];

      document.dispatchEvent(
        new CustomEvent('barcode-scan', { detail: { raw: text, text } })
      );
    },
    onScanBox(index) {
      if (this.form.scanned.currentScanBoxIndex === index) {
        this.form.scanned.currentScanBoxIndex = null;
      } else {
        this.form.scanned.currentScanBoxIndex = index;
      }
    },
    async onSubmit() {
      this.loadingSubmit = true;

      const [, err] = await this.request(
        `/api/v1/stock-batches/${this.stockBatch.data.id}/-actions/submit`,
        {
          method: 'patch',
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
          timeout: 1000,
        });
      } else {
        this.$router.push({ name: 'persiapan-stok' });
      }

      this.loadingSubmit = false;
    },
    async scanProduct(qrcode) {
      this.loadingSubmitScan = true;

      const [, err] = await this.request(
        `/api/v1/stock-batches/${this.stockBatch.data.id}/-actions/scan-qrcode`,
        {
          method: 'post',
          data: {
            type: 'product',
            qrcode,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
          timeout: 1000,
        });
      } else {
        this.form.scanned.product_qty++;
        this.form.scanned.qrcodes.push(qrcode);
      }

      this.loadingSubmitScan = false;
    },
    async scanBox(qrcode) {
      this.loadingSubmitScan = true;

      const [res, err] = await this.request(
        `/api/v1/stock-batches/${this.stockBatch.data.id}/-actions/scan-qrcode`,
        {
          method: 'post',
          data: {
            type: 'box',
            qrcode,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
          timeout: 1000,
        });
      } else {
        this.form.scanned.boxes.push({
          id: res.data.id,
          name: null,
          qty: 0,
          volume: null,
          productBoxUuid: null,
          originalProductBoxUuid: null,
        });

        this.form.scanned.currentScanBoxIndex =
          this.form.scanned.boxes.length - 1;
        this.form.scanned.qrcodes.push(qrcode);
      }

      this.loadingSubmitScan = false;
    },
    async scanProductBox(qrcode) {
      this.loadingSubmitScan = true;

      const box =
        this.form.scanned.boxes[this.form.scanned.currentScanBoxIndex];

      const [, err] = await this.request(
        `/api/v1/stock-batches/${this.stockBatch.data.id}/-actions/scan-qrcode`,
        {
          method: 'post',
          data: {
            type: 'product',
            qrcode,
            box_id: box.id,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
          timeout: 1000,
        });
      } else {
        this.form.scanned.boxes[this.form.scanned.currentScanBoxIndex].qty++;
        this.form.scanned.qrcodes.push(qrcode);
      }

      this.loadingSubmitScan = false;
    },
  },
  created() {
    this.loadStockBatch();
  },
  mounted() {
    document.addEventListener('barcode-scan', this.onQrScanned);
  },
  destroyed() {
    document.removeEventListener('barcode-scan', this.onQrScanned);
  },
};
</script>
